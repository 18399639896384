import React, { useState } from "react";
import FormikControl from "../components/formik/FormikControl";
import { SignupUser, AddDonor, findLocation } from "../utils/api";
import { Link } from "react-router-dom";

import { Formik, Form } from "formik";
import { Spin } from "antd";
import { toast } from "react-toastify";
import {
  bgOptions,
  ageOptions,
  medOptions,
  genderOptions,
  initialRegisterState,
  provinceOptions,
} from "../utils/supportFuncs";
import { registerValidationSchema } from "../utils/validationSchemas";

const Register = ({ history }) => {
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const handleSignUp = async (values) => {
    if (!navigator.onLine) {
      return toast.error("Not connected to internet");
    }
    setLoading(true);
    const {
      email,
      fname,
      pass,
      phone,
      homeAddress,
      age,
      bloodgroup,
      firsttimedonor,
      ishealthy,
      tehsil,
      province,
    } = values;

    const { data } = await findLocation({ province, tehsil });

    const newUserPayload = {
      email,
      fname,
      username: email.split("@", [1]).toString(),
      pass,
      phone,
      accType: "2",
      homeAddress,
    };

    const newDonorPayload = {
      locationID: data[0].ID,
      age,
      userPhone: phone,
      hasDonated: firsttimedonor === "yes" ? "0" : "1",
      isHealthy: ishealthy === "yes" ? "1" : "0",
      bloodGroup: bloodgroup,
      address: homeAddress,
    };

    SignupUser(newUserPayload)
      .then((res) => {
        console.log(res);
        if (res.data.Error) {
          setLoading(false);
          return toast.error(res.data.Error);
        } else {
          AddDonor(newDonorPayload)
            .then((res) => {
              if (res.data.Code === 500) {
                setLoading(false);
                return toast.error(res.data.Message);
              } else {
                toast.info("Registered! Login to update profile.");
                setLoading(false);
                history.push("/login");
              }
            })
            .catch((err) => console.log(err.response.data.Error));
        }
      })
      .catch((err) => {
        toast.error(err.response.data.Error);
        setLoading(false);
      });
  };

  return (
    <Formik
      initialValues={initialRegisterState}
      validationSchema={registerValidationSchema}
      onSubmit={(values) => handleSignUp(values)}
    >
      {(formik) => {
        return (
          <div className="container p-5">
            <div className="row">
              <div className="col-md-6 offset-md-3">
                <h4 className="text-danger">Register to Donate</h4>
                <Form>
                  <h6>Personal Details</h6>
                  <FormikControl
                    control="input"
                    type="text"
                    placeholder="Full Name"
                    name="fname"
                  />
                  <FormikControl
                    control="input"
                    type="text"
                    placeholder="Mobile no. (e.g. 0300XXXXXXX)"
                    name="phone"
                  />
                  <FormikControl
                    control="input"
                    type="number"
                    placeholder="Cnic no. (Optional)"
                    name="cnic"
                  />
                  <FormikControl
                    control="select"
                    options={ageOptions}
                    name="age"
                  />
                  <FormikControl
                    control="radio"
                    label="Gender"
                    options={genderOptions}
                    name="gender"
                  />
                  <br />
                  <h6>Address Details</h6>
                  <FormikControl
                    control="input"
                    type="text"
                    list="provinces"
                    options={provinceOptions}
                    placeholder="Province"
                    name="province"
                  />
                  {console.log(formik)}
                  {formik.values.province && (
                    <FormikControl
                      control="input"
                      type="text"
                      list="cities"
                      placeholder="City/ Tehsil"
                      name="tehsil"
                    />
                  )}
                  <FormikControl
                    control="input"
                    type="text"
                    placeholder="Residential Address"
                    name="homeAddress"
                  />
                  <br />
                  <h6>Medical Details</h6>
                  <FormikControl
                    control="select"
                    options={bgOptions}
                    name="bloodgroup"
                  />
                  <FormikControl
                    control="radio"
                    label="First Time Donor?"
                    options={medOptions}
                    name="firsttimedonor"
                  />
                  <FormikControl
                    control="radio"
                    label="Are you Healthy?"
                    options={medOptions}
                    name="ishealthy"
                  />
                  <br />
                  <h6>Email / Password</h6>
                  <FormikControl
                    control="input"
                    type="text"
                    placeholder="Email"
                    name="email"
                  />
                  <FormikControl
                    control="input"
                    type="password"
                    placeholder="Password"
                    name="pass"
                  />
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value={checked}
                      id="termCheckBox"
                      onChange={(e) => setChecked(!checked)}
                    />
                    <label
                      className="form-check-label text-secondary"
                      htmlFor="termCheckBox"
                      style={{ fontSize: "0.7rem" }}
                    >
                      I agree to the website{" "}
                      <Link
                        to="/terms"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Terms & Conditions
                      </Link>{" "}
                      and <Link to="privacypolicy">Privacy Policy</Link>
                    </label>
                  </div>
                  <br />
                  <div className="row d-flex justify-content-around">
                    <Spin spinning={loading}>
                      <button
                        type="submit"
                        className="btn btn-danger btn-raised mt-2"
                        disabled={!checked || !formik.isValid}
                      >
                        REGISTER
                      </button>
                    </Spin>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        );
      }}
    </Formik>
  );
};

export default Register;
