import React from "react";
import {
  FacebookFilled,
  TwitterSquareFilled,
  InstagramFilled,
  LinkedinFilled,
  MailFilled,
} from "@ant-design/icons";

const Toolbar = () => (
  <div
    className="container-fluid p-2 d-sm-block d-none"
    style={{ background: "#d4342e", opacity: "0.9", maxHeight: "35px" }}
  >
    <div className="row">
      <div
        className="d-flex col-md-2 text-left text-light justify-content-around"
        style={{ fontSize: "120%" }}
      >
        <a
          className="text-light"
          href="https://web.facebook.com/kherkun"
          target="_blank"
          rel="noreferrer"
          style={{ color: "white" }}
        >
          <FacebookFilled />
        </a>
        <a
          className="text-light"
          href="https://twitter.com/KherKunPk?s=08"
          target="_blank"
          rel="noreferrer"
          style={{ color: "white" }}
        >
          <TwitterSquareFilled />
        </a>
        <a
          className="text-light"
          href="https://www.instagram.com/kherkun.pk/"
          target="_blank"
          rel="noreferrer"
          style={{ color: "white" }}
        >
          <InstagramFilled />
        </a>
        <a
          className="text-light"
          href="https://www.linkedin.com/company/kher-kun/"
          target="_blank"
          rel="noreferrer"
          style={{ color: "white" }}
        >
          <LinkedinFilled />
        </a>
      </div>
      <a
        className="col-md-10 text-right text-light"
        href="mailto:kherkunpk@gmail.com"
        style={{ fontWeight: "bold" }}
      >
        <MailFilled />
        {"   "} kherkunpk @gmail.com
      </a>
    </div>
  </div>
);

export default Toolbar;
