import React from "react";
import { Field, ErrorMessage } from "formik";
import TextError from "../texterror/TextError";

function Select(props) {
  const { label, name, options, ...rest } = props;

  return (
    <div className="form-group">
      {label && (
        <label htmlFor={name} className="text-primary">
          {label}
        </label>
      )}
      <Field
        className="form-control"
        as="select"
        id={name}
        name={name}
        {...rest}
      >
        {options.map((option) => {
          return (
            <option key={option.value} value={option.value}>
              {option.key}
            </option>
          );
        })}
      </Field>
      <ErrorMessage component={TextError} name={name} />
    </div>
  );
}

export default Select;
