import React from "react";
import { Field, ErrorMessage } from "formik";
import TextError from "../texterror/TextError";

function RadioButtons(props) {
  const { label, name, options, ...rest } = props;
  return (
    <div className="d-flex">
      <label>{`${label}:`}</label>

      <div className="mx-1">
        <Field name={name}>
          {({ field }) => {
            return options.map((option) => {
              return (
                <React.Fragment key={option.key}>
                  <input
                    type="radio"
                    id={`${name}-${option.value}`}
                    {...field}
                    {...rest}
                    value={option.value}
                    checked={field.value === option.value}
                  />{" "}
                  <label htmlFor={option.value}>{option.key}</label>{" "}
                </React.Fragment>
              );
            });
          }}
        </Field>
      </div>
      <ErrorMessage component={TextError} name={name} />
    </div>
  );
}

export default RadioButtons;
