import React, { createContext, useState, useMemo } from "react";

export const userContext = createContext({ user: {}, modal: true });

const Context = ({ children }) => {
  const [user, setUser] = useState({});
  const [modal, setModal] = useState(true);

  const provided = useMemo(
    () => ({
      value: { user, modal },
      setValue: { setUser, setModal },
    }),
    [user, modal]
  );
  return (
    <userContext.Provider value={provided}>{children}</userContext.Provider>
  );
};

export default Context;
