import React, { useState } from "react";
import FormikControl from "../components/formik/FormikControl";
import { SignupUser, addReq, getDonor, findLocation } from "../utils/api";
import { Link } from "react-router-dom";
import { Formik, Form } from "formik";
import { Spin } from "antd";
import { toast } from "react-toastify";
import {
  bloodPlatsOptions,
  condOptions,
  bgOptions,
  initialDonationRequestState,
  ageOptions,
  provinceOptions,
} from "../utils/supportFuncs";
import { donationRequestValidationSchema } from "../utils/validationSchemas";

const DonationRequest = ({ history }) => {
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const handleDonationRequest = async (values) => {
    if (!navigator.onLine) {
      return toast.error("Not connected to internet");
    }
    setLoading(true);
    const guestUserPayload = {
      fname: values.name,
      phone: values.userPhone,
      accType: "3",
    };
    const { data } = await findLocation({
      province: values.province,
      tehsil: values.tehsil,
    });
    values.locationID = data[0].ID;

    const res = await getDonor({ phone: values.userPhone });

    res && res.data.length === 0
      ? SignupUser(guestUserPayload)
          .then((res) => {
            if (res.data.Error) {
              setLoading(false);
              return toast.error(res.data.Message);
            } else {
              addReq(values)
                .then((res) => {
                  toast.info(
                    "Request submitted, our ambassador will contact you shortly"
                  );
                  setLoading(false);
                  history.push("/");
                })
                .catch((err) => console.log(err));
            }
          })
          .catch((err) => console.log(err))
      : addReq(values)
          .then((res) => {
            toast.info(
              "Request submitted, our ambassador will contact you shortly"
            );
            setLoading(false);
            history.push("/");
          })
          .catch((err) => console.log(err));
  };

  return (
    <Formik
      initialValues={initialDonationRequestState}
      validationSchema={donationRequestValidationSchema}
      onSubmit={(values) => handleDonationRequest(values)}
    >
      {(formik) => {
        return (
          <div className="container p-5" style={{ minHeight: "67vh" }}>
            <div className="row">
              <div className="col-md-6 offset-md-3">
                <h4 className="text-danger">Donation Request</h4>
                <Form>
                  <h6>Requester Details</h6>

                  <FormikControl
                    control="input"
                    type="text"
                    placeholder="Full Name"
                    name="name"
                  />
                  <FormikControl
                    control="input"
                    type="string"
                    placeholder="Mobile no. (e.g. 0321XXXXXXX)"
                    name="userPhone"
                  />
                  <br />
                  <h6>Recipient Location</h6>
                  <FormikControl
                    control="input"
                    type="text"
                    list="provinces"
                    options={provinceOptions}
                    placeholder="Province"
                    name="province"
                  />
                  {formik.values.province && (
                    <FormikControl
                      control="input"
                      type="text"
                      list="cities"
                      placeholder="City/ Tehsil"
                      name="tehsil"
                    />
                  )}
                  <FormikControl
                    control="input"
                    type="text"
                    placeholder="Hospital Name"
                    name="address"
                  />
                  <br />
                  <h6>Request Details</h6>
                  <FormikControl
                    control="select"
                    options={bgOptions}
                    name="bloodGroup"
                  />
                  <FormikControl
                    control="select"
                    options={ageOptions}
                    name="age"
                  />
                  <FormikControl
                    control="select"
                    options={bloodPlatsOptions}
                    name="typeID"
                  />
                  <FormikControl
                    control="input"
                    type="number"
                    placeholder="No. of Units/ Bags"
                    name="bagsRequired"
                  />
                  <FormikControl
                    control="select"
                    options={condOptions}
                    name="conditionID"
                  />
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value={checked}
                      id="termCheckBox"
                      onChange={(e) => setChecked(!checked)}
                    />
                    <label
                      className="form-check-label text-secondary"
                      htmlFor="termCheckBox"
                      style={{ fontSize: "0.7rem" }}
                    >
                      I agree to the website{" "}
                      <Link
                        to="/terms"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Terms & Conditions
                      </Link>{" "}
                      and <Link to="privacypolicy">Privacy Policy</Link>
                    </label>
                  </div>
                  <br />
                  <div className="row d-flex justify-content-around">
                    <Spin spinning={loading}>
                      <button
                        type="submit"
                        className="btn btn-danger btn-raised mt-2"
                        disabled={!checked || !formik.isValid}
                      >
                        Submit
                      </button>
                    </Spin>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        );
      }}
    </Formik>
  );
};

export default DonationRequest;
