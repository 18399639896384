import React from "react";
import { Spin } from "antd";

const LazyHero = React.lazy(() => import("../components/hero-image/Hero"));
const LazyVisMis = React.lazy(() => import("../components/VisMis/VisMis"));

const Home = () => {
  return (
    <>
      <React.Suspense
        fallback={
          <div
            className="container d-flex align-items-center"
            style={{ minHeight: "67vh" }}
          >
            <div style={{ margin: "100px auto" }}>
              <Spin tip="Loading..." spinning={true} size={"large"} />
            </div>
          </div>
        }
      >
        <LazyHero />
        <LazyVisMis />
      </React.Suspense>
    </>
  );
};

export default Home;
