import React from 'react'
import NavBar from '../nav/NavBar'
import Toolbar from '../toolbar/Toolbar'

const Header = () => {
    return (
        <>
        <Toolbar/>
        <NavBar/>
        </>
    )
}

export default Header
