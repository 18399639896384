export const bloodGroups = ["A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-"];
export const provinces = [
  "AZAD JAMMU AND KASHMIR",
  "BALOCHISTAN",
  "FATA",
  "FEDERAL CAPITAL",
  "GILGIT BALTISTAN",
  "KHYBER PAKHTUNKHWA",
  "PUNJAB",
  "SINDH",
];
export const ageArray = Array.from({ length: 44 }, (_, i) => i + 17);

const conditionArray = ["critical", "stable"];
const bloodPlatsArray = ["blood", "platelets"];
export const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const ageOptions = ageArray.map((age) => ({ key: age, value: age }));
ageOptions.unshift({ key: "Age", value: "" });

export const provinceOptions = provinces.map((province) => ({
  key: province,
  value: province,
}));
provinceOptions.unshift({ key: "Province", value: "" });

export const bgOptions = bloodGroups.map((bg) => ({ key: bg, value: bg }));
bgOptions.unshift({ key: "Blood Group", value: "" });

export const condOptions = conditionArray.map((cond, num) => ({
  key: cond,
  value: (num + 1).toString(),
}));
condOptions.unshift({ key: "Patient Condition", value: "" });

export const bloodPlatsOptions = bloodPlatsArray.map((key, num) => ({
  key,
  value: (num + 1).toString(),
}));
bloodPlatsOptions.unshift({ key: "Blood/ Platelets", value: "" });

export const initialDonationRequestState = {
  locationID: "1",
  age: "",
  userPhone: "",
  typeID: "",
  conditionID: "",
  bloodGroup: "",
  bagsRequired: "",
  address: "",
  name: "",
  province: "",
  tehsil: "",
};

export const initialRegisterState = {
  email: "",
  locationID: "1",
  fname: "",
  username: "",
  phone: "",
  cnic: "",
  age: "",
  province: "",
  tehsil: "",
  gender: "",
  homeAddress: "",
  bloodgroup: "",
  firsttimedonor: "",
  ishealthy: "",
  pass: "",
  acctype: "1",
};

export const genderOptions = [
  { key: "Male", value: "male" },
  { key: "Female", value: "female" },
];

export const medOptions = [
  { key: "Yes", value: "yes" },
  { key: "No", value: "no" },
];

export const isActiveOptions = [
  { key: "Yes", value: "Yes" },
  { key: "No", value: "No" },
];

export const smsRequester =
  "Dear Requester,\n \n We have received your request for blood donation, we are looking for a donor to match your request.\n \n Regards KVBB Admin";

export const smsDonor =
  "Dear Donor,\n \n There is a donation request for your blood group. Are you willing to donate?.\n \n Regards KVBB Admin";
