import { Tooltip } from "antd";
import React from "react";
import { Link } from "react-router-dom";

const Ambassador = () => {
  return (
    <div className="container pb-3">
      <div className="row">
        <div className="col">
          <h3 className="text-danger">Want to become an Ambassador?</h3>
          <hr />
          <h5>Responsibilities</h5>
          <ul>
            <li>managing blood bank database</li>
            <li>handling blood requests and queries</li>
            <li>connecting blood donors and recipients</li>
            <li>
              building database for local blood banks and healthcare facilities
            </li>
          </ul>
          <br />
          <h5>Eligibility</h5>
          <ul>
            <li>a currently enrolled university student</li>
            <li> able to use computer and cellphone</li>
            <li>able to provide extra documents for identity verification</li>
            <li>
              able to work 6 hours per month (service hours 10 hours- 5
              volunteers) 300 hours per month-50 volunteers and at least 2 hours
              consecutive in a day
            </li>
          </ul>
          <br />
          <Tooltip title="Currently disabled, we will soon start registrations">
            <Link to="/register-ambassador">
              <button className="btn btn-danger btn-raised" disabled>
                Register
              </button>
            </Link>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default Ambassador;
