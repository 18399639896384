import axios from "axios";

export const SignupUser = async (newUser) => {
  return await axios.post(process.env.REACT_APP_USER_SIGNUP, newUser, {
    headers: {
      "X-Api-Key": process.env.REACT_APP_AWS_KEY,
      "Content-Type": "application/json",
    },
  });
};

export const AddDonor = async (newDonor) => {
  return await axios.post(process.env.REACT_APP_ADD_DONOR, newDonor, {
    headers: {
      "X-Api-Key": process.env.REACT_APP_AWS_KEY,
      "Content-Type": "application/json",
    },
  });
};

export const getUser = async (userDetails) => {
  return await axios.get(process.env.REACT_APP_GET_USER, {
    headers: {
      "X-Api-Key": process.env.REACT_APP_AWS_KEY,
      username: userDetails.username,
      password: userDetails.password,
      "Content-Type": "application/json",
    },
  });
};

export const getDonor = async (queryParams) => {
  return await axios.get(process.env.REACT_APP_GET_DONOR, {
    params: queryParams,
    headers: {
      "X-Api-Key": process.env.REACT_APP_AWS_KEY,
      "Content-Type": "application/json",
    },
  });
};

export const addReq = async (reqData) => {
  return await axios.post(process.env.REACT_APP_ADD_REQ, reqData, {
    headers: {
      "X-Api-Key": process.env.REACT_APP_AWS_KEY,
      "Content-Type": "application/json",
    },
  });
};

export const getReq = async () => {
  return await axios.get(process.env.REACT_APP_GET_REQ, {
    headers: {
      "X-Api-Key": process.env.REACT_APP_AWS_KEY,
      "Content-Type": "application/json",
    },
  });
};

export const updateReq = async (reqData) => {
  return await axios.post(process.env.REACT_APP_UPDATE_REQ, reqData, {
    headers: {
      "X-Api-Key": process.env.REACT_APP_AWS_KEY,
      "Content-Type": "application/json",
    },
  });
};

export const findLocation = async (queryParams) => {
  return await axios.get(process.env.REACT_APP_FIND_LOCATION, {
    params: queryParams,
    headers: {
      "X-Api-Key": process.env.REACT_APP_AWS_KEY,
      "Content-Type": "application/json",
    },
  });
};

export const updateDonor = async (reqData) => {
  return await axios.post(process.env.REACT_APP_UPDATE_DONOR, reqData, {
    headers: {
      "X-Api-Key": process.env.REACT_APP_AWS_KEY,
      "Content-Type": "application/json",
    },
  });
};

export const updateUser = async (reqData, params) => {
  return await axios.post(process.env.REACT_APP_UPDATE_USER, reqData, {
    params,
    headers: {
      "X-Api-Key": process.env.REACT_APP_AWS_KEY,
      "Content-Type": "application/json",
    },
  });
};
