import * as Yup from "yup";
import { phoneRegExp } from "./supportFuncs";

export const donationRequestValidationSchema = Yup.object({
  name: Yup.string().required("Name required"),
  age: Yup.number().required("Age required"),
  userPhone: Yup.string()
    .matches(phoneRegExp, "Phone number is not valid")
    .min(11, "Phone number is not valid")
    .max(11, "Phone number is not valid")
    .required("mobile no. required"),
  province: Yup.string().required("province required"),
  tehsil: Yup.string().required("city required"),
  address: Yup.string().required("hospital name required"),
  bloodGroup: Yup.string().required("bloodgroup required"),
  typeID: Yup.string().required("Required"),
  bagsRequired: Yup.number()
    .max(5, "Max 5 can be requested")
    .required("Required"),
  conditionID: Yup.string().required("Required"),
});

export const registerValidationSchema = Yup.object({
  fname: Yup.string().required("Name required"),
  email: Yup.string().email("Invalid email").required("Email required"),
  phone: Yup.string()
    .matches(phoneRegExp, "Phone number is not valid")
    .min(11, "Phone number is not valid")
    .max(11, "Phone number is not valid")
    .required("mobile no. required"),
  age: Yup.number().required("age required"),
  gender: Yup.string().required("gender required"),
  province: Yup.string().required("province required"),
  tehsil: Yup.string().required("city required"),
  homeAddress: Yup.string().required("address required"),
  bloodgroup: Yup.string().required("bloodgroup required"),
  firsttimedonor: Yup.string().required(
    "First Time donor confirmation required"
  ),
  ishealthy: Yup.string().required("Are you Healthy? confirmation required"),
  pass: Yup.string().min(6, "min 6 characters").required("password required"),
});

export const updateDonorProfileValidationSchema = Yup.object({
  userPhone: Yup.string()
    .matches(phoneRegExp, "Phone number is not valid")
    .min(11, "Phone number is not valid")
    .max(11, "Phone number is not valid")
    .required("mobile no. required"),
  age: Yup.number().required("age required"),

  province: Yup.string().required("province required"),
  tehsil: Yup.string().required("city required"),
  address: Yup.string().required("address required"),
  bloodGroup: Yup.string().required("bloodgroup required"),
});

export const userUpdateValidationSchema = Yup.object({
  fname: Yup.string().required("Name required"),
  email: Yup.string().email("Invalid email").required("Email required"),
  phone: Yup.string()
    .matches(phoneRegExp, "Phone number is not valid")
    .min(11, "Phone number is not valid")
    .max(11, "Phone number is not valid")
    .required("mobile no. required"),
  pass: Yup.string().min(6, "min 6 characters").required("password required"),
});
