import React from "react";
import { Route, useHistory } from "react-router-dom";

const UserRoute = ({ children, ...rest }) => {
  const history = useHistory();
  const user = JSON.parse(window.sessionStorage.getItem("user"));

  return (
    <>{user && user.username ? <Route {...rest} /> : history.push("/login")}</>
  );
};

export default UserRoute;
