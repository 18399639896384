import React, { useEffect, useState } from "react";
import { Field, ErrorMessage, useFormikContext } from "formik";
import TextError from "../texterror/TextError";
import { findLocation } from "../../utils/api";

function Input(props) {
  const [cities, setCities] = useState([]);
  const { label, placeholder, name, list, ...rest } = props;

  const citiesArray = [...new Set(cities.map((val) => val.Tehsil))];
  const updatedPlaceholder =
    name === "tehsil"
      ? cities.length > 0
        ? placeholder
        : "Loading Cities..."
      : placeholder;
  const {
    values: { province },
  } = useFormikContext();
  useEffect(() => {
    if (province) {
      let timer = setTimeout(
        () => findLocation({ province }).then((res) => setCities(res.data)),
        1000
      );
      return () => {
        clearTimeout(timer);
      };
    }
  }, [province]);

  return (
    <div className="form-group label-floating">
      {label && (
        <label htmlFor={name} className="text-primary control-label">
          {label}
        </label>
      )}
      <Field
        className="form-control"
        list={list}
        id={name}
        name={name}
        disabled={
          updatedPlaceholder === "Loading Cities..." ||
          placeholder === "update_mobile"
        }
        placeholder={updatedPlaceholder}
        {...rest}
      />
      <datalist id={list}>
        {name === "tehsil" || name === "Tehsil"
          ? citiesArray.map((city) => <option key={city} value={city} />)
          : list &&
            rest.options.map((option) => (
              <option key={option.value} value={option.value} />
            ))}
      </datalist>
      <ErrorMessage component={TextError} name={name} />
    </div>
  );
}

export default Input;
