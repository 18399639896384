import React from "react";
import {
  MailOutlined,
  FacebookFilled,
  TwitterSquareFilled,
  InstagramFilled,
  LinkedinFilled,
} from "@ant-design/icons";

const Footer = () => {
  return (
    <footer
      className="container-fluid footer"
      style={{
        background: "#d4342e",
        opacity: "0.9",
      }}
    >
      <div className="container-fluid pt-2">
        <div className="d-flex justify-content-around row pt-1 text-justify">
          <div className="col-sm-4 mb-2">
            <h5 className="text-center">Contact Us</h5>
            <div className="d-flex justify-content-center">
              <a style={{ color: "black" }} href="mailto:kherkunpk@gmail.com">
                <MailOutlined />
                {} kherkunpk@gmail.com
              </a>
            </div>
          </div>

          <div className="col-sm-4 mb-2">
            <h5 className="text-center">Follow Us</h5>
            <div className="d-flex justify-content-between">
              <div>
                <a
                  href="https://web.facebook.com/kherkun"
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <FacebookFilled />
                  {} Facebook
                </a>
              </div>
              <div>
                <a
                  href="https://twitter.com/KherKunPk?s=08"
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <TwitterSquareFilled />
                  {} Twitter
                </a>
              </div>
              <div>
                <a
                  href="https://www.instagram.com/kherkun.pk/"
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <InstagramFilled />
                  {} Instagram
                </a>
              </div>
              <div>
                <a
                  href="https://www.linkedin.com/company/kher-kun/"
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <LinkedinFilled />
                  {} LinkedIn
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className="font-weight-bold footer-copyright text-center pb-2">
        © 2021 Copyright:
        <a href="www.kherkun.com"> kherkun.com</a>
      </div>
    </footer>
  );
};

export default Footer;
