import React, { useEffect, useContext } from "react";
import Home from "./pages/Home";
import "react-toastify/dist/ReactToastify.css";
import "antd/dist/antd.css";
import Header from "./components/header/Header";
import { Switch, Route } from "react-router-dom";
import { Spin } from "antd";
import Register from "./pages/Register";
import Footer from "./components/footer/Footer";
import DonationRequest from "./pages/DonationRequest";
import Ambassador from "./pages/Ambassador";
import RegisterAmbassador from "./pages/RegisterAmbassador";
import { ToastContainer, Zoom } from "react-toastify";
import { userContext } from "./utils/Context";
import UserRoute from "./routes/UserRoute";
import AdminRoute from "./routes/AdminRoute";
import Financial from "./pages/Financial";

function App() {
  const {
    setValue: { setUser },
  } = useContext(userContext);

  const LazyUserProfile = React.lazy(() => import("./pages/UserProfile"));
  const LazyLogin = React.lazy(() => import("./pages/Login"));
  const LazyKnowledgeCenter = React.lazy(() =>
    import("./pages/KnowledgeCenter")
  );
  const LazyAdmin = React.lazy(() => import("./pages/Admin"));
  const LazyAbout = React.lazy(() => import("./pages/About"));
  const LazyFaq = React.lazy(() => import("./pages/Faqs"));
  const LazyTerms = React.lazy(() => import("./pages/TermsOfUse"));
  const LazyPrivacy = React.lazy(() => import("./pages/PrivacyPolicy"));

  useEffect(() => {
    const user = JSON.parse(window.sessionStorage.getItem("user"));
    if (user) {
      setUser(user);
    } else {
      setUser({});
    }
  }, [setUser]);

  return (
    <>
      <div className="__wrapper">
        <Header />
        <ToastContainer
          autoClose={1000}
          hideProgressBar={true}
          transition={Zoom}
        />
        <React.Suspense
          fallback={
            <div
              className="container d-flex align-items-center"
              style={{ minHeight: "67vh" }}
            >
              <div style={{ margin: "100px auto" }}>
                <Spin tip="Loading..." spinning={true} size={"large"} />
              </div>
            </div>
          }
        >
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/register" component={Register} />
            <Route path="/donation-request" component={DonationRequest} />
            <Route path="/financial-donation" component={Financial} />
            <Route path="/become-ambassador" component={Ambassador} />
            <Route path="/register-ambassador" component={RegisterAmbassador} />
            <Route path="/login" component={LazyLogin} />
            <Route path="/about-us" component={LazyAbout} />
            <Route path="/terms" component={LazyTerms} />
            <Route path="/privacypolicy" component={LazyPrivacy} />
            <Route path="/knowledge-center" component={LazyKnowledgeCenter} />
            <Route path="/faq" component={LazyFaq} />
            <AdminRoute path="/admin" component={LazyAdmin} />
            <UserRoute path="/userprofile" component={LazyUserProfile} />
          </Switch>
        </React.Suspense>
      </div>
      <Footer />
    </>
  );
}

export default App;
