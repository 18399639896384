import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import Context from "./utils/Context";
import reportWebVitals from "./reportWebVitals";
import { Workbox } from "workbox-window";

ReactDOM.render(
  <Context>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Context>,

  document.getElementById("root")
);

if ("serviceWorker" in navigator) {
  const wb = new Workbox("service-worker.js");

  wb.addEventListener("installed", (event) => {
    if (event.isUpdate) {
      if (
        window.confirm(
          `Newer version of app available. Click OK to update and close the app & reopen for changes to take effect`
        )
      ) {
        window.location.reload();
        wb.messageSkipWaiting();
      }
    }
  });

  wb.register();
}

reportWebVitals();
