import React from "react";

const RegisterAmbassador = () => {
  return (
    <div className="container p-5">
      <div className="row">
        <div className="col-md-6 offset-md-3">
          <h4 className="text-danger">Register as an Ambassador</h4>
          <br />
          <form>
            <h6>Personal Details</h6>
            <div className="form-group">
              <input
                className="form-control"
                type="text"
                //    value={email}
                placeholder="Full Name"
                //    onChange={e=>setEmail(e.target.value)}
                autoFocus
              />
            </div>
            <div className="form-group">
              <input
                className="form-control"
                type="text"
                //    value={email}
                placeholder="Mobile No."
                //    onChange={e=>setEmail(e.target.value)}
              />
            </div>
            <div className="form-group">
              <input
                className="form-control"
                type="text"
                //    value={email}
                placeholder="CNIC No."
                //    onChange={e=>setEmail(e.target.value)}
              />
            </div>
            <div className="form-group">
              <select className="form-control" id="age" name="age">
                <option>Age</option>
                <option value="18">18</option>
                <option value="19">19</option>
                <option value="20">20</option>
                <option value="21">21</option>
              </select>
            </div>

            <div className="d-flex">
              Gender: {"       "}
              <div class="mx-1 custom-control custom-radio">
                <input
                  type="radio"
                  id="customRadio1"
                  name="customRadio"
                  className="custom-control-input"
                />
                <label className="custom-control-label" for="customRadio1">
                  Male
                </label>
              </div>
              <div className="custom-control custom-radio">
                <input
                  type="radio"
                  id="customRadio2"
                  name="customRadio"
                  className="custom-control-input"
                />
                <label className="custom-control-label" for="customRadio2">
                  Female
                </label>
              </div>
            </div>
            <br />
            <h6>Address Details</h6>
            <div className="form-group">
              <input
                className="form-control"
                type="text"
                //    value={email}
                placeholder="Residential Address"
                //    onChange={e=>setEmail(e.target.value)}
              />
            </div>
            <div className="form-group">
              <input
                className="form-control"
                type="text"
                //    value={email}
                placeholder="Division"
                //    onChange={e=>setEmail(e.target.value)}
              />
            </div>
            <div className="form-group">
              <input
                className="form-control"
                type="text"
                //    value={email}
                placeholder="District"
                //    onChange={e=>setEmail(e.target.value)}
              />
            </div>
            <div className="form-group">
              <input
                className="form-control"
                type="text"
                //    value={email}
                placeholder="City"
                //    onChange={e=>setEmail(e.target.value)}
              />
            </div>
            <br />
            <h6>Medical Details</h6>
            <div className="form-group">
              <select className="form-control" id="age" name="age">
                <option>Blood Group</option>
                <option value="A+">A+</option>
                <option value="A-">A-</option>
                <option value="B+">B+</option>
                <option value="B-">B-</option>
                <option value="AB+">AB+</option>
                <option value="AB-">AB-</option>
                <option value="O+">O+</option>
                <option value="O-">O-</option>
              </select>
            </div>
            <div className="d-flex">
              First Time Donor: {"       "}
              <div class="mx-1 custom-control custom-radio">
                <input
                  type="radio"
                  id="customRadio3"
                  name="customRadio"
                  className="custom-control-input"
                />
                <label className="custom-control-label" for="customRadio3">
                  Yes
                </label>
              </div>
              <div className="custom-control custom-radio">
                <input
                  type="radio"
                  id="customRadio4"
                  name="customRadio"
                  className="custom-control-input"
                />
                <label className="custom-control-label" for="customRadio4">
                  No
                </label>
              </div>
            </div>
            <div className="d-flex">
              Are you Healthy?: {"       "}
              <div class="mx-1 custom-control custom-radio">
                <input
                  type="radio"
                  id="customRadio5"
                  name="customRadio"
                  className="custom-control-input"
                />
                <label className="custom-control-label" for="customRadio5">
                  Yes
                </label>
              </div>
              <div className="custom-control custom-radio">
                <input
                  type="radio"
                  id="customRadio6"
                  name="customRadio"
                  className="custom-control-input"
                />
                <label className="custom-control-label" for="customRadio6">
                  No
                </label>
              </div>
            </div>
            <br />
            <h6>Password</h6>
            <div className="form-group">
              <input
                className="form-control"
                type="password"
                //    value={email}
                placeholder="Password"
                //    onChange={e=>setEmail(e.target.value)}
              />
            </div>
            <div className="form-group">
              <input
                className="form-control"
                type="Password"
                //    value={email}
                placeholder="Confirm Password"
                //    onChange={e=>setEmail(e.target.value)}
              />
            </div>
            <div className="row d-flex justify-content-around">
              <button className="btn btn-danger btn-raised mt-2" type="submit">
                Register
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default RegisterAmbassador;
