import React, { useState, useContext } from "react";
import { Menu, Tooltip } from "antd";
import { Link } from "react-router-dom";
import { MenuOutlined } from "@ant-design/icons";
import logo from "../../assets/images/logo.png";
import { userContext } from "../../utils/Context";
import { Avatar } from "antd";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

const { Item, SubMenu } = Menu;

const NavBar = () => {
  const history = useHistory();
  const {
    value: { user },
    setValue: { setUser },
  } = useContext(userContext);
  const [current, setCurrent] = useState("");
  const handleClick = (e) => {
    setCurrent(e.key);
  };

  let abbr =
    user.name &&
    user.name
      .split(" ")
      .map(function (item) {
        return item[0];
      })
      .join("");

  const handleLogOut = () => {
    window.sessionStorage.removeItem("user");
    setUser({});
    toast.info("successfully logged out");
    history.push("/");
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div
          className="col-8 col-md-4 font-weight-bold"
          style={{
            height: "52px",
            fontFamily: "Chango, cursive",
          }}
        >
          <Link to="/">
            <div style={{ display: "inline-block" }}>
              <img
                src={logo}
                alt="kher-kun-logo"
                style={{ height: "45px", width: "45px" }}
              />
            </div>
            <div
              style={{
                display: "inline-block",
                height: "45px",
                paddingTop: "17px",
              }}
            >
              <span style={{ color: "black" }}>KHER-</span>
              <span style={{ color: "#d4342e" }}>KUN</span>
            </div>
          </Link>
        </div>
        <div
          className="d-flex d-md-block col-md-8 col-4 justify-content-end"
          style={{ height: "52px" }}
        >
          <Menu
            onClick={handleClick}
            selectedKeys={[current]}
            mode="horizontal"
            overflowedIndicator={<MenuOutlined />}
          >
            <Item key="home" className="customClass">
              <Link to="/">Home</Link>
            </Item>

            <Item key="about">
              <Link to="/about-us">About Us</Link>
            </Item>

            <Item disabled="true" key="request">
              <Tooltip title="Currently disabled, we will soon start taking blood donation requests">
                <span>
                  <Link to="/donation-request">Blood Donation Request</Link>
                </span>
              </Tooltip>
            </Item>
            <Item key="finance">
              <span>
                <Link to="/financial-donation">Financial Donations</Link>
              </span>
            </Item>

            <SubMenu key="Initiative-submenu" title="Other Initiatives">
              <Menu.ItemGroup>
                <Item disabled="true" key="drive">
                  <Link to="">
                    Blood Drive<sup>Coming Soon</sup>
                  </Link>
                </Item>

                <Item disabled="true" key="screening">
                  <Link to="">
                    Disease Screening<sup>Coming Soon</sup>
                  </Link>
                </Item>
              </Menu.ItemGroup>
            </SubMenu>
            <SubMenu key="Support-SubMenu" title="Support">
              <Menu.ItemGroup>
                <Menu.Item key="faq">
                  <Link to="/faq">FAQ's</Link>
                </Menu.Item>
                <Menu.Item key="knowledge">
                  <Link to="/knowledge-center">Knowledge Center</Link>
                </Menu.Item>
                <Menu.Item key="ambassador">
                  <Link to="/become-ambassador">Become an Ambassador</Link>
                </Menu.Item>
              </Menu.ItemGroup>
            </SubMenu>
            {user && user?.username ? (
              <SubMenu
                key="User-SubMenu"
                title={<Avatar size={30}>{abbr}</Avatar>}
              >
                <Menu.ItemGroup>
                  <Item key="profile">
                    <Link to="/userprofile">Profile</Link>
                  </Item>
                  {user?.usertype === 1 && (
                    <Item key="admin">
                      <Link to="/admin">Admin Dashboard</Link>
                    </Item>
                  )}

                  <Item key="logout" onClick={handleLogOut}>
                    Logout
                  </Item>
                </Menu.ItemGroup>
              </SubMenu>
            ) : (
              <SubMenu key="Log-Register-SubMenu" title="Login/ Register">
                <Menu.ItemGroup>
                  <Item key="login">
                    <Link to="/login">Login</Link>
                  </Item>

                  <Item key="register">
                    <Link to="/register">Register</Link>
                  </Item>
                </Menu.ItemGroup>
              </SubMenu>
            )}
          </Menu>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
